<template>
    <div style="width:100%;float:left;">
        <div class="d-block d-lg-none" style="position:fixed;bottom:25px;left:25px;z-index:99999;" @click="popupFilter()">
            <span class="fa-stack fa-2x">
                <i class="fas fa-circle fa-stack-2x" style="height:40px;"></i>
                <i class="fas fa-filter fa-stack-1x fa-inverse" style="height:40px;"></i>
            </span>
        </div>
        <div class="d-none d-md-none d-lg-block" id="buttonWrapper">
            <div class="bandonHeader float-left">
                <div class="switchWrap" style="margin-left:15px;">
                    <a style="margin-right:5px;">Show All</a>
                    <label class="switch" for="checkbox">
                        <input type="checkbox" id="checkbox" v-model="hideZero"/>
                        <div class="slider round"></div>
                    </label>
                    <a style="margin-left:5px;">Hide Out of Stock</a>
                </div>
                <div class="switchWrap" style="margin-left:15px;">
                    <a style="margin-right:5px;">Show By Style</a>
                    <label class="switch" for="colorcheckbox">
                        <input type="checkbox" id="colorcheckbox" @change="showByColorChanged()" v-model="showByColor"/>
                        <div class="slider round"></div>
                    </label>
                    <a style="margin-left:5px;">Show By Color</a>
                </div>
                <!-- <div class="searchBar" style="margin-bottom:10px;margin-top:15px;margin-left:15px;">
                    <input type="input" class="form-control" style="padding-right:50px;" id="search" v-model="searchValue" placeholder="Find SKU...">
                    <button type="button" @click="searchFunction()"  class="impButton impButtonColors btn" style="border-top-left-radius:0px;border-bottom-left-radius:0px;"><i class="fas fa-search"></i></button>
                </div> -->
                <v-select v-if="configureAdmin() && customers" style="margin-bottom:10px;width:246px;float:left;margin-top:15px;background-color:white;margin-left:15px;" label="customer_name" placeholder="Filter By Customer" v-model="customer" :options="customers"></v-select>
                <v-select v-if="configureBoyne() && customers" style="margin-bottom:10px;width:246px;float:left;margin-top:15px;background-color:white;margin-left:15px;" label="customer_name" placeholder="Filter By Customer" v-model="customer" :options="customers"></v-select>
                <v-select v-if="pai_styles" multiple style="margin-bottom:10px;width:246px;float:left;margin-top:15px;background-color:white;margin-left:15px;" placeholder="Filter By Style..." v-model="styleFilter" :options="pai_styles"></v-select>
                <v-select v-if="color_options" multiple style="margin-bottom:10px;width:246px;float:left;margin-top:15px;margin-left:15px;background-color:white;" placeholder="Filter By Color..." v-model="colorFilter" :options="color_options"></v-select>
                <!-- <a>Showing items 1- of </a> -->
            </div>
        </div>
        <!-- <div class="d-block d-md-none" id="smallButtonWrapper"></div> -->
        <div style="width:100%;float:left;margin-top:15px;">
            <div v-for="value in filterData" @click="orderShow(value)" v-if="!loading && (computeTotalQuantity(value)>0 && hideZero || !hideZero)" style="cursor:pointer;" class="col-sm-6 col-md-4 col-lg-4 float-left">
                <CCard>
                  <CCardBody>
                    <div class="itemWrap">
                      <div class="topData">
                          <!-- <span> SKU: <b>{{value[0].customer_style}}</b> </span></br> -->
                          <span> Style: <b>{{value[0].customer_number === '93563' ? value[0].description : value[0].booking_style}}</b> </span></br>
                          <span> Total Avail: <b>{{computeTotalQuantity(value)}}</b> </span></br>
                          <!-- <span> Color: <b>{{value.booking_color}}</b> </span></br> -->
                          <!-- <span> Size: <b>{{value.size}}</b> </span> -->
                      </div>
                      <div style="padding-top:100%;"></div>
                      <div class="imgContainer" style="padding-left:5px;padding-right:5px;">
                          <!-- <img :src="value[0].route" alt="" title=""></img> -->
                          <!-- <app-img :lazy-src="value[0].route"></app-img> -->
                          <app-img :lazy-src="$apiAdress + '/storage/hat_images/' + value[0].customer_style + '-FRONT.jpg'"></app-img>
                          <!-- <img src="http://127.0.0.1:8000/storage/hat_images/9255050001-BACK.jpg" alt="" title=""></img> -->
                      </div>
                      <!-- <div class="price">
                          Retail Price: ${{value.retail_price}}</br>
                          Our Price: <b style="border-bottom:2px solid #C92227;">${{value.retail_price}}</b></br>
                          Stock: <b>{{value.available_units}}</b>
                      </div> -->
                    </div>
                  </CCardBody>
                </CCard>
            </div>
            <div v-show="loading || otherLoading" id="loader" style="float:left;width:100%;text-align:center;">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Loading ...
            </div>
            <!-- <div class="col-12 text-center float-left pb-5" v-if="!loading">
                <button class="btn btn-sm loadButton impButtonColors" @click="loadMore()">Load More</button>
            </div> -->
        </div>
        <!-- <div id="bottomSmallSection" class="d-block d-lg-none">
            <div v-for="value in filterData" @click="orderShow(value)" v-if="!loading && (value.available_units>0 && hideZero || !hideZero)" style="cursor:pointer;" class="item col-sm-6 col-md-4 col-lg-3 float-left">
                <CCard>
                  <CCardBody>
                    <div class="itemWrap">
                      <div class="topData">
                          <span> SKU: <b>{{value.customer_style}}</b> </span></br>
                          <span> Color: <b>{{value.customer_color}}</b> </span></br>
                          <span> Size: <b>{{value.size}}</b> </span>
                      </div>
                      <div style="padding-top:100%;"></div>
                      <div class="imgContainer">
                          <img :src="this.$apiAdress + '/storage/hat_images/' + value.customer_style + '.jpg'"></img>
                      </div>
                      <div class="price">
                          Retail Price: ${{value.retail_price}}</br>
                          Our Price: <b style="border-bottom:2px solid #C92227;">${{value.retail_price}}</b></br>
                          Stock: <b>{{value.available_units}}</b>
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
            </div>
            <div v-show="loading || otherLoading" id="loader" style="float:left;width:100%;text-align:center;">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Loading ...
            </div>
            <div class="col-12 text-center float-left pb-5" v-if="!loading">
                <button class="btn btn-sm loadButton impButtonColors" @click="loadMore()">Load More</button>
            </div>
        </div> -->
        <CModal
          title="Choose File"
          :show.sync="currentOrderModal"
        >
          <template v-if="currentOrder" #header>
            <!-- Modal content-->
            {{currentOrder.customer_number === '93563' ? currentOrder.description : currentOrder.booking_style}}
          </template>
          <div style="width:100%;" v-if="currentOrder && currentOrderModal">
            <div class="col-12 float-left text-center" style="height:200px;" v-if="currentOrder.customer_style">
                <!-- <img style="max-height:500px;max-width:100%;" :src="$apiAdress + '/storage/hat_images/' + currentOrder.customer_style + '-FRONT.jpg'"> -->
                <div class="leftArrow" @click="pictureClicked()">
                  <CIcon name="cil-chevron-left"/>
                </div>
                <app-img v-if="!picLoading" style="max-height:200px;max-width:100%;" :lazy-src="$apiAdress + '/storage/hat_images/' + currentOrder.customer_style + '-' + pictureShown + '.jpg'"></app-img>

                <div class="rightArrow" @click="pictureClicked()">
                  <CIcon name="cil-chevron-right"/>
                </div>
            </div>
            <div class="col-12 float-left text-center" style="height:200px;" v-else-if="order_options && currentOrder.color_selected !== ''">
                <!-- <img style="max-height:500px;max-width:100%;" :src="$apiAdress + '/storage/hat_images/' + currentOrder.customer_style + '-FRONT.jpg'"> -->
                <div class="leftArrow" @click="allPicCustomerClicked('left')">
                  <CIcon name="cil-chevron-left"/>
                </div>
                <div style="width:100%;float:left;" v-for="(val, index) in configureColorOptions()">
                  <app-img v-if="picShow === index && !picShowLoading" style="max-height:200px;max-width:100%;" :lazy-src="$apiAdress + '/storage/hat_images/' + val.customer_style + '-' + picShowWay + '.jpg'"></app-img>
                </div>

                <div class="rightArrow" @click="allPicCustomerClicked('right')">
                  <CIcon name="cil-chevron-right"/>
                </div>
            </div>
            <div class="col-12 float-left text-center" style="height:200px;" v-else-if="order_options">
                <!-- <img style="max-height:500px;max-width:100%;" :src="$apiAdress + '/storage/hat_images/' + currentOrder.customer_style + '-FRONT.jpg'"> -->
                <div class="leftArrow" @click="allPicClicked('left')">
                  <CIcon name="cil-chevron-left"/>
                </div>
                <div style="width:100%;float:left;" v-for="(val, index) in order_options">
                  <app-img v-if="picShow === index && !picShowLoading" style="max-height:200px;max-width:100%;" :lazy-src="$apiAdress + '/storage/hat_images/' + val.customer_style + '-' + picShowWay + '.jpg'"></app-img>
                </div>

                <div class="rightArrow" @click="allPicClicked('right')">
                  <CIcon name="cil-chevron-right"/>
                </div>
            </div>
            <div class="col-12 float-left text-center" style="height:200px;" v-else>
                <!-- <img style="max-height:500px;max-width:100%;" :src="$apiAdress + '/storage/hat_images/' + currentOrder.customer_style + '-FRONT.jpg'"> -->
                <!-- <app-img style="max-height:500px;max-width:100%;" :lazy-src="$apiAdress + '/storage/hat_images/default.jpg'"></app-img> -->
            </div>
            <!-- <div style="width:50%;height:24px;padding-right:5px;" class="float-left text-right">
                Color:
            </div>
            <div style="width:50%;height:24px;padding-left:5px;" class="float-left text-left">
                {{currentOrder.customer_color}}
            </div>
            <div style="width:50%;height:24px;padding-right:5px;" class="float-left text-right">
                Size:
            </div>
            <div style="width:50%;height:24px;padding-left:5px;" class="float-left text-left">
                {{currentOrder.size}}
            </div> -->
            <div style="width:100%;float:left;padding:5px;margin-top:10px;">
                <div style="width:50%;padding-right:5px;line-height:30px;" class="float-left text-right">
                    Color:
                </div>
                <div style="width:50%;padding-left:5px;line-height:24px;" class="float-left text-left">
                    <select v-if="colorOptions" @change="colorChanged()" v-model="currentOrder.color_selected" class="form-control">
                        <option value="">Select Color</option>
                        <option :value="val" v-for="val in colorOptions">{{val.customer_color}} ({{val.booking_color}})</option>
                    </select>
                </div>
            </div>
            <div style="width:100%;float:left;padding:5px;margin-top:10px;">
                <div style="width:50%;padding-right:5px;line-height:30px;" class="float-left text-right">
                    Size:
                </div>
                <div style="width:50%;padding-left:5px;line-height:24px;" class="float-left text-left">
                    <select v-if="sizeOptions" @change="sizeChanged()" v-model="currentOrder.size" class="form-control">
                        <option value="">Select Size</option>
                        <option :value="val" v-for="val in sizeOptions">{{val}}</option>
                    </select>
                </div>
            </div>
            <div style="width:100%;float:left;padding:5px;margin-top:10px;">
                <div style="width:50%;padding-right:5px;line-height:30px;" class="float-left text-right">
                    Style:
                </div>
                <div style="width:50%;padding-left:5px;line-height:24px;" class="float-left text-left">
                    <select v-if="styleOptions" @change="styleChanged()" v-model="currentOrder.customer_style" class="form-control">
                        <option value="">Select Style</option>
                        <option :value="val" v-for="val in styleOptions">{{val}}</option>
                    </select>
                </div>
            </div>
            <div style="width:50%;height:24px;padding-right:5px;margin-top:10px;line-height:24px;" class="float-left text-right">
                Available Units:
            </div>
            <div style="width:50%;height:24px;padding-left:5px;margin-top:10px;line-height:24px;" class="float-left text-left">
                {{currentOrder.available_units}}
            </div>
            <div style="width:225px;margin-left:calc((100% - 225px) / 2);margin-top:10px;float:left;">
                <a style="float:left;line-height:30.75px;">Order Quantity : </a>
                <input type="number" class="form-control" :disabled="parseFloat(currentOrder.available_units) <= 0" :max="currentOrder.available_units" @keyup="checkQuantity()" placeholder="units" style="width:100px;margin-left:10px;text-align:center;float:left;" v-model="currentOrder.quantity_ordered" />
            </div>
            <div style="width:100%;float:left;padding:5px;margin-top:10px;">
              <div style="width:50%;height:24px;padding-right:5px;line-height:24px;" class="float-left text-right">
                  Total Price:
              </div>
              <div style="width:50%;height:24px;padding-left:5px;line-height:24px;" class="float-left text-left">
                  ${{configureTotalPrice()}}
              </div>
            </div>
          </div>
          <template #footer v-if="currentOrder">
            <CButton @click="currentOrder = null, currentOrderModal = false" color="danger">Cancel</CButton>
            <CButton color="success" :disabled="checkDisabled()" @click="addToCart()">Add To Cart</CButton>
          </template>
        </CModal>
        <div id="filterModal" class="modal fade" role="dialog">
          <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title float-left">Filter / Search</h4>
                <button type="button" class="close" data-dismiss="modal"><i class="fas fa-times" style="color:#000;line-height:36px;height:36px;"></i></button>
              </div>
              <div class="modal-body" style="width:276px; margin:auto;">
                <div class="switchWrap">
                    <a style="margin-right:5px;">Show All</a>
                    <label class="switch" for="checkbox">
                        <input type="checkbox" id="checkbox" v-model="hideZero"/>
                        <div class="slider round"></div>
                    </label>
                    <a style="margin-left:5px;">Hide Out of Stock</a>
                </div>
                <div class="searchBar" style="margin-bottom:10px;margin-top:15px;">
                    <input type="input" class="form-control" style="padding-right:50px;" @keyup.enter="searchFunction()" id="search" v-model="searchValue" placeholder="Find SKU...">
                    <button type="button" @click="searchFunction()"  class="impButton impButtonColors btn" style="border-top-left-radius:0px;border-bottom-left-radius:0px;"><i class="fas fa-search"></i></button>
                </div>
                <v-select v-if="pai_styles" multiple style="margin-bottom:10px;width:246px;float:left;margin-top:15px;background-color:white;" placeholder="Filter By PAi Blank..." v-model="styleFilter" :options="pai_styles"></v-select>
                <v-select v-if="color_options" multiple style="margin-bottom:10px;width:246px;float:left;margin-top:15px;background-color:white;margin-bottom:20px;" placeholder="Filter By Color..." v-model="colorFilter" :options="color_options"></v-select>
              </div>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
var methods = {}
import swal from 'sweetalert2'
import AppImg from './app_img'
import { mapGetters } from 'vuex'

methods.showByColorChanged = function () {
  window.localStorage.setItem('show_by_color', this.showByColor)
  this.getInventory()
}

methods.configureRoute = function (value) {
  console.log(value[0].customer_style + '-FRONT.jpg')
  return value[0].customer_style + '-FRONT.jpg'
}

methods.pictureClicked = function () {
  if (this.pictureShown === 'FRONT') {
    this.pictureShown = 'BACK';
  } else {
    this.pictureShown = 'FRONT'
  }
  this.picLoading = true
  var that = this
  setTimeout(function () {
    that.picLoading = false
  }, 100)
}

methods.checkDisabled = function () {
  if (this.currentOrder) {
    if (this.currentOrder.customer_color && this.currentOrder.customer_style && this.currentOrder.quantity_ordered) {
      return false
    }
  }
    return true
}

methods.configureAdmin = function () {
  if (this.$store.state.user) {
    if (this.$store.state.user.roles.indexOf('admin') > -1 || this.$store.state.user.roles.indexOf('csr') > -1) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

methods.configureBoyne = function () {
  if (this.$store.state.user) {
    if (this.$store.state.user.roles.indexOf('boyne') > -1 || this.$store.state.user.roles.indexOf('csr') > -1 || this.$store.state.user.roles.indexOf('admin') > -1) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
}

methods.configureColorOptions = function () {
  var that = this
  var arr = []
  this.order_options.forEach(function (val) {
    if (val.customer_color === that.currentOrder.color_selected.customer_color) {
      arr.push(val)
    }
  })
  return arr
}

methods.allPicCustomerClicked = function (way) {
  this.picShowLoading = true
  var arr = this.configureColorOptions()
  if (way === 'right') {
    if (this.picShow === arr.length - 1) {
      if (this.picShowWay === 'BACK') {
        this.picShow = 0
        this.picShowWay = 'FRONT'
      } else {
        this.picShowWay = 'BACK'
      }
    } else {
      console.log('less')
      if (this.picShowWay === 'BACK') {
        this.picShow++
        this.picShowWay = 'FRONT'
      } else {
        this.picShowWay = 'BACK'
      }
      console.log(this.picShowWay)
    }
  } else {
    if (this.picShow === 0) {
      if (this.picShowWay === 'FRONT') {
        this.picShow = arr.length - 1
        this.picShowWay = 'BACK'
      } else {
        this.picShowWay = 'FRONT'
      }
    } else {
      console.log('less')
      if (this.picShowWay === 'FRONT') {
        this.picShow--
        this.picShowWay = 'BACK'
      } else {
        this.picShowWay = 'FRONT'
      }
      console.log(this.picShowWay)
    }
  }
  var that = this
  setTimeout(function () {
    that.picShowLoading = false
  }, 100)
  this.$forceUpdate()
}

methods.configureTotalPrice = function () {
  var total = parseFloat(0)
  if (this.currentOrder && this.currentOrder.quantity_ordered) {
    total = this.currentOrder.quantity_ordered * parseFloat(this.currentOrder.our_price)
  }
  return total.toFixed(2)
}

methods.getInventory = function() {
  var that = this
    const postData = {
        user: this.user,
        offset: 0,
        limit: 1000,
        styles: this.styleFilter,
        colors: this.colorFilter,
        customer: this.customer,
        showByColor: this.showByColor
    }
    console.log(postData)
    axios.post(this.$apiAdress + '/api/getInventory?token=' + localStorage.getItem("api_token"), postData).then(response => {
        console.log('GETTING INVENTORY')
        console.log(response);
        // if (!this.data) {
        //     this.data = response.data;
        // } else if (this.oldOffset !== this.offset) {
        //     this.oldOffset = this.offset
        //     this.data = this.data.concat(response.data)
        // } else {
        //     this.data = response.data
        // }
        var tags = []
        if (!this.data) {
            this.data = []
            var obj = {}
            response.data.forEach(function (val) {
              var n = ''
              if (that.showByColor) {
                n = val.booking_style + '_' + val.customer_color
              } else {
                n = val.booking_style
              }
                if (!obj[n]) {
                    obj[n] = []
                }
                // if (val.tags) {
                //     val.tags.forEach(function (tag) {
                //         if (tags.indexOf(tag) < 0) {
                //             tags.push(tag)
                //         }
                //     })
                // }
                console.log(val.customer_style)
                val.route = that.$apiAdress + '/storage/hat_images/' + val.customer_style + '-FRONT.jpg'
                obj[n].push(val)
            })
            // this.tags = tags
            var arr = []
            for (var key in obj) {
                arr.push(obj[key])
            }
            this.data = arr
            console.log(this.data)
        } else if (this.oldOffset !== this.offset) {
            var obj = {}
            response.data.forEach(function (val) {
              var n = ''
              if (that.showByColor) {
                n = val.booking_style + '_' + val.customer_color
              } else {
                n = val.booking_style
              }
                if (!obj[n]) {
                    obj[n] = []
                }
                obj[n].push(val)
            })
            var arr = []
            for (var key in obj) {
                arr.push(obj[key])
            }
            this.data = this.data.concat(arr)
        } else {
            var obj = {}
            response.data.forEach(function (val) {
              var n = ''
              if (that.showByColor) {
                n = val.booking_style + '_' + val.customer_color
              } else {
                n = val.booking_style
              }
                if (!obj[n]) {
                    obj[n] = []
                }
                obj[n].push(val)
            })
            var arr = []
            for (var key in obj) {
                arr.push(obj[key])
            }
            this.data = obj
        }
        this.filterData = this.data
        var arr = []
        var obj = {}
        var colorObj = {}
        var colorArr = []
        // this.data.forEach(function (val) {
        //     if (!obj[val.customer_style] && val.available_units > 0) {
        //         obj[val.customer_style] = val.customer_style
        //         arr.push(val.customer_style)
        //     }
        //     if (!colorObj[val.customer_color] && val.available_units > 0) {
        //         colorObj[val.customer_color] = val.customer_color
        //         colorArr.push(val.customer_color)
        //     }
        // })
        arr.sort()
        colorArr.sort()
        // this.color_options = colorArr
        // this.pai_styles = arr
        this.loading = false
        this.otherLoading = false
    })
}

methods.allPicClicked = function (way) {
  console.log(this.picShowWay)
  console.log(this.picShow)
  this.picShowLoading = true
  if (way === 'right') {
    if (this.picShow === this.order_options.length - 1) {
      if (this.picShowWay === 'BACK') {
        this.picShow = 0
        this.picShowWay = 'FRONT'
      } else {
        this.picShowWay = 'BACK'
      }
    } else {
      console.log('less')
      if (this.picShowWay === 'BACK') {
        this.picShow++
        this.picShowWay = 'FRONT'
      } else {
        this.picShowWay = 'BACK'
      }
      console.log(this.picShowWay)
    }
  } else {
    if (this.picShow === 0) {
      if (this.picShowWay === 'FRONT') {
        this.picShow = this.order_options.length - 1
        this.picShowWay = 'BACK'
      } else {
        this.picShowWay = 'FRONT'
      }
    } else {
      console.log('less')
      if (this.picShowWay === 'FRONT') {
        this.picShow--
        this.picShowWay = 'BACK'
      } else {
        this.picShowWay = 'FRONT'
      }
      console.log(this.picShowWay)
    }
  }
  var that = this
  setTimeout(function () {
    that.picShowLoading = false
  }, 100)
  this.$forceUpdate()
}

methods.computeTotalQuantity = function (value) {
    var total = 0
    value.forEach(function (val) {
        total += parseFloat(val.available_units)
    })
    return total
}

methods.getFilters = function () {
    const postData = {
        user: this.user
    }
    console.log(postData)
    axios.post(this.$apiAdress + '/api/getFilters?token=' + localStorage.getItem("api_token"), postData).then(response => {
        if (response.status === 200) {
            console.log('filter')
            console.log(response)
            this.color_options = response.data.colors
            this.pai_styles = response.data.styles
            this.customers = response.data.customers
        }
    })
}

methods.orderShow = function (value) {
    var obj = {}
    // for (var key in value) {
    //     obj[key] = value[key]
    // }
    var arr = []
    var arr2 = []
    var colorObj = {}
    this.picShow = 0
    var imgArr = []
    var size = []
    var sizeObj = {}
    console.log('order show')
    value.forEach(function (val) {
      console.log(val)
        var obj = {
            customer_color: val.customer_color,
            booking_color: val.booking_color
        }
        if (!colorObj[val.customer_color]) {
            colorObj[val.customer_color] = val.customer_color
            arr.push(obj)
        }
        if (!sizeObj[val.size]) {
          console.log('found')
          console.log(obj)
          sizeObj[val.size] = val.size
          size.push(val.size)
        }
        // arr2.push(val.size)
    })
    this.colorOptions = arr
    // this.sizeOptions = arr2
    var obj2 = {
        id: '',
        customer_number: value[0].customer_number,
        customer_name: value[0].customer_name,
        customer_style: '',
        customer_color: '',
        description: '',
        booking_style: value[0].booking_style,
        booking_color: '',
        pai_color: '',
        size: '',
        available_units: '',
        color_selected: '',
        size_selected: '',
        quantity_ordered: '',
        our_price: '',
        retail_price: ''
    }
    console.log(size)
    if (size.length === 1) {
      obj2.size = size[0]
    }
    if (arr.length === 1) {
      obj2.color_selected = arr[0]
    }
    // obj.quantity_ordered = ''
    this.order_options = value
    console.log(this.order_options)
    this.currentOrder = obj2

    if (obj2.color_selected) {
      this.colorChanged()
    }
    // var obj = {
    //     id: value.id,
    //     pai_style: value.pai_style,
    //     pai_color: value.pai_color,
    //     pai_variation: value.pai_variation,
    //     sku: value.sku,
    //     customer_color: value.customer_color,
    //     size: value.size,
    //     price: value.price,
    //     image: value.image,
    //     units: value.available_units,
    //     quantity_ordered: ''
    // }
    this.currentOrderModal = true
    // this.$refs.orderModal.show()
    // $('#orderModal').modal('show')
}

methods.sizeChanged = function () {
    var that = this
    this.picShowLoading = true
    this.currentOrder.quantity = ''
    var styles = []
    var styles2 = []
    this.order_options.forEach(function (val) {
        if (val.customer_color === that.currentOrder.color_selected.customer_color && val.size === that.currentOrder.size) {
            // that.currentOrder.available_units = val.available_units
            // that.currentOrder.description = val.description
            // that.currentOrder.customer_style = val.customer_style
            // that.currentOrder.customer_color = val.customer_color
            // that.currentOrder.booking_color = val.booking_color
            // that.currentOrder.pai_color = val.pai_color
            // that.currentOrder.our_price = val.our_price
            // that.currentOrder.retail_price = val.retail_price
            // that.currentOrder.id = val.id
            styles.push(val.customer_style)
            styles2.push(val)
        }
    })
    if (styles.length === 1) {
      that.currentOrder.available_units = styles[2].available_units
      that.currentOrder.description = styles[2].description
      that.currentOrder.customer_style = styles[2].customer_style
      that.currentOrder.customer_color = styles[2].customer_color
      that.currentOrder.booking_color = styles[2].booking_color
      that.currentOrder.pai_color = styles[2].pai_color
      that.currentOrder.our_price = styles[2].our_price
      that.currentOrder.retail_price = styles[2].retail_price
      that.currentOrder.id = styles[2].id
    }
    this.styleOptions = styles
    this.picShowLoading = false
}

methods.colorChanged = function () {
    var arr = []
    var that = this
    this.picShowLoading = true
    this.picLoading = true
    this.currentOrder.size = ''
    this.currentOrder.quantity = ''
    this.currentOrder.customer_style = ''
    this.picShow = 0
    var sizeObj ={}
    var sizeArr = []
    var arrObj = {}
    this.order_options.forEach(function (val) {
        if (val.customer_color === that.currentOrder.color_selected.customer_color) {
          if (!arrObj[val.size]) {
            arrObj[val.size] = val.size
            arr.push(val.size)
          }
        }
        if (!sizeObj[val.size]) {
          console.log('eyyy')
          sizeObj[val.size] = val.size
          sizeArr.push(val)
        }
    })
    var styles = []
    var styles2 = []
    if (sizeArr.length === 1) {
      this.currentOrder.size = sizeArr[0].size
    }

    this.order_options.forEach(function (val) {
      if (val.customer_color === that.currentOrder.color_selected.customer_color) {
          // that.currentOrder.available_units = val.available_units
          // that.currentOrder.description = val.description
          // that.currentOrder.customer_style = val.customer_style
          // that.currentOrder.customer_color = val.customer_color
          // that.currentOrder.booking_color = val.booking_color
          // that.currentOrder.pai_color = val.pai_color
          // that.currentOrder.our_price = val.our_price
          // that.currentOrder.retail_price = val.retail_price
          // that.currentOrder.id = val.id
          styles.push(val.customer_style)
          styles2.push(val)
      }
    })

    if (styles.length === 1) {
      that.currentOrder.available_units = styles2[0].available_units
      that.currentOrder.description = styles2[0].description
      that.currentOrder.customer_style = styles2[0].customer_style
      that.currentOrder.customer_color = styles2[0].customer_color
      that.currentOrder.booking_color = styles2[0].booking_color
      that.currentOrder.pai_color = styles2[0].pai_color
      that.currentOrder.our_price = styles2[0].our_price
      that.currentOrder.retail_price = styles2[0].retail_price
      that.currentOrder.id = styles2[0].id
    }
    this.styleOptions = styles
    this.sizeOptions = arr
    setTimeout(function () {
      that.picShowLoading = false
      that.picLoading = false
    }, 100)
}

methods.styleChanged = function () {
  var that = this
  this.picShowLoading = true
  this.picLoading = true
  this.order_options.forEach(function (val) {
    if (val.customer_color === that.currentOrder.color_selected.customer_color && val.size === that.currentOrder.size && val.customer_style === that.currentOrder.customer_style) {
        that.currentOrder.available_units = val.available_units
        that.currentOrder.description = val.description
        that.currentOrder.customer_style = val.customer_style
        that.currentOrder.customer_color = val.customer_color
        that.currentOrder.booking_color = val.booking_color
        that.currentOrder.pai_color = val.pai_color
        that.currentOrder.our_price = val.our_price
        that.currentOrder.retail_price = val.retail_price
        that.currentOrder.id = val.id
    }
  })
  setTimeout(function () {
    that.picShowLoading = false
    that.picLoading = false
  }, 100)
}

methods.popupFilter = function () {
    $('#filterModal').modal('show')
}

methods.loadMore = function () {
    this.otherLoading = true
    this.offset += this.limit
    this.getInventory()
}

methods.addToCart = function () {
    if (parseFloat(this.currentOrder.quantity_ordered) && parseFloat(this.currentOrder.quantity_ordered) > 0) {
        if (window.localStorage.getItem('cart')) {
            var cart = JSON.parse(window.localStorage.getItem('cart'))
            cart.push(this.currentOrder)
            window.localStorage.setItem('cart', JSON.stringify(cart))
        } else {
            var cart = [this.currentOrder]
            window.localStorage.setItem('cart', JSON.stringify(cart))        
        }
        this.$store.dispatch('setCart')
        this.currentOrderModal = false
        swal.fire({
          title: 'Success!',
          text: "You have added the order to your cart.",
          type: 'success',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Go To Checkout',
          cancelButtonText: 'Continue Shopping',
          confirmButtonColor: '#000',
        }).then((result) => {
          if (result.value) {
            this.$router.push('/checkout')
          }
        })
    } else {
        swal('Oops!', 'You need to adjust the quantity in order to add it to your cart.', 'error')
    }
}

methods.checkQuantity = function () {
    if (parseFloat(this.currentOrder.quantity_ordered) > parseFloat(this.currentOrder.available_units)) {
        this.currentOrder.quantity_ordered = this.currentOrder.available_units
    }
}

methods.order = function () {
    if (parseFloat(this.currentOrder.quantity_ordered) <= parseFloat(this.currentOrder.available_units)) {
        console.log(this.currentOrder)
    } else {
        var that = this
        swal('Oops!', "You are trying to order more than we have in stock. The quantity will be updated to what we have in inventory.", "error").then(res => {
            that.$refs.orderModal.show()
        })
        this.currentOrder.quantity_ordered = this.currentOrder.available_units
        // this.$refs.orderModal.show()
    }
}

methods.searchFunction = function () {
    var that = this
    if (this.searchValue !== '') {
        var arr = []
        var search = that.searchValue.toUpperCase()
        // console.log(search)
        // this.data.forEach(function (value) {
        //     if (value.sku.includes(search)) {
        //         arr.push(value)
        //     }
        // })
        // this.filterData = arr
        const postData = {
            search: this.searchValue,
            user: this.user
        }
        axios.post(this.$apiAdress + '/api/searchSku?token=' + localStorage.getItem("api_token"), postData).then(response => {
            if (response.status === 200) {
                if (response.data.length) {
                    this.filterData = response.data
                } else {
                    swal('Not Found', 'The sku number you are trying to search could not be found.', 'error')
                }
            }
        })
    } else {
        this.filterData = this.data
    }
}

methods.configureBoth = function () {
    var that = this
    var arr = []
    this.loading = true
    this.data.forEach(function (val) {
        if (that.styleFilter.indexOf(val.customer_style) > -1 && that.colorFilter.indexOf(val.customer_color) > -1) {
            arr.push(val)
        }
    })
    this.filterData = arr
    setTimeout(function () {
        that.loading = false
    }, 500)
}

methods.configureStyle = function () {
    var that = this
    var arr = []
    this.loading = true
    this.data.forEach(function (val) {
        if (that.styleFilter.indexOf(val.customer_style) > -1) {
            arr.push(val)
        }
    })
    this.filterData = arr
    setTimeout(function () {
        that.loading = false
    }, 500)
}

methods.configureColor = function () {
    var that = this
    var arr = []
    this.loading = true
    this.data.forEach(function (val) {
        if (that.colorFilter.indexOf(val.customer_color) > -1) {
            arr.push(val)
        }
    })
    this.filterData = arr
    setTimeout(function () {
        that.loading = false
    }, 500)
}

methods.getFilterData = function () {
    // this.searchValue = ''
    if (this.styleFilter.length || this.colorFilter.length) {
        const postData = {
            user: this.user,
            style: this.styleFilter,
            color: this.colorFilter,
            customer: this.customer
        }
        console.log(postData)
        axios.post(this.$apiAdress + '/api/getFilterData?token=' + localStorage.getItem("api_token"), postData).then(response => {
            if (response.status === 200) {
                console.log(response)
                this.filterData = response.data
            }
        })
    } else {
        this.filterData = this.data
    }
}

export default {
    // ===Component name
    name: "InventoryPage",
    // ===Props passed to component
    props: {
        
    },
    middleware: 'confirmed',
    // ===Components used by this component
    components: {
        AppImg
    },
    // ====component Data properties
    data: function (){
        return {
            data: null,
            hideZero: 0,
            showByColor: 0,
            pictureShown: 'FRONT',
            picLoading: false,
            currentOrderModal: false,
            picShowLoading: false,
            styleOptions: null,
            picShow: 0,
            picShowWay: 'FRONT',
            searchValue: '',
            styleFilter: [],
            colorFilter: [],
            filterData: null,
            loadedItems:0,
            totalItems:0,
            loading: true,
            currentOrder: null,
            pai_styles: [],
            styleFilter: [],
            colorFilter: [],
            color_options: [],
            offset: 0,
            oldOffset: 0,
            limit: 25,
            otherLoading: true,
            colorOptions: null,
            order_options: null,
            sizeOptions: null,
            customers: '',
            customer: null
        }
    },
    watch: {
        'searchValue': function () {
            if (this.searchValue === '') {
                this.filterData = this.data
            }
        },
        'styleFilter': function () {
            // var arr = []
            // if (this.styleFilter === '' || this.styleFilter === null || !this.styleFilter.length) {
            //     if (!this.colorFilter.length) {
            //         this.filterData = this.data
            //     } else {
            //         this.configureColor()
            //     }
            // } else {
            //     if (!this.colorFilter.length) {
            //         this.configureStyle()
            //     } else {
            //         this.configureBoth()
            //     }
            // }
            this.loading = true
            this.offset = 0
            this.oldOffset = 0
            this.limit = 25
            this.getInventory()
        },
        'customer': function () {
            this.loading = true
            this.offset = 0
            this.oldOffset = 0
            this.limit = 25
            this.getInventory()
        },
        'hideZero': function () {
            var obj = {}
            var arr = []
            this.loading = true
            if (this.hideZero) {
                this.data.forEach(function (val) {
                    if (!obj[val.customer_style] && val.available_units > 0) {
                        obj[val.customer_style] = val.customer_style
                        arr.push(val.customer_style)
                    }
                })
            } else {
                this.data.forEach(function (val) {
                    if (!obj[val.customer_style]) {
                        obj[val.customer_style] = val.customer_style
                        arr.push(val.customer_style)
                    }
                })
            }
            arr.sort()
            this.pai_styles = arr
            this.loading = false
        },
        'colorFilter': function () {
            // var arr = []
            // if (this.colorFilter === '' || this.colorFilter === null || !this.colorFilter.length) {
            //     if (!this.styleFilter.length) {
            //         this.filterData = this.data
            //     } else {
            //         this.configureStyle()
            //     }
            // } else {
            //     if (!this.styleFilter.length) {
            //         this.configureColor()
            //     } else {
            //         this.configureBoth()
            //     }
            // }
            this.loading = true
            this.oldOffset = 0
            this.offset = 0
            this.limit = 25
            this.getInventory()
        }
    },
    // ===Code to be executed when Component is mounted
    mounted() {
        console.log('showing user')
        console.log(this.$store.state.user)
        if (window.localStorage.getItem('show_by_color')) {
          this.showByColor = window.localStorage.getItem('show_by_color')
        }
        this.getInventory()
        this.getFilters()
    },
    // ===Component methods
    methods: methods
}
</script>
<!-- styles -->
<!-- adding scoped attribute will apply the css to this component only -->
<style scoped lang="scss">



#loader{
  width: 150px;
  height: 40px;
  border-radius: 100%;
  position: relative;
  margin: 40px auto;
  text-align:center;
  font-weight:bold;
  font-family: helvetica;
}

#loader span{
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
}

#loader span:nth-child(1){
  animation: bounce 1s ease-in-out infinite,pulse 2s infinite alternate;
}
#loader span:nth-child(2){
  animation: bounce 1s ease-in-out 0.2s infinite,pulse 2s infinite alternate;
}
#loader span:nth-child(3){
  animation: bounce 1s ease-in-out 0.4s infinite,pulse 2s infinite alternate;
}
#loader span:nth-child(4){
  animation: bounce 1s ease-in-out 0.6s infinite,pulse 2s infinite alternate;
}
#loader span:nth-child(5){
  animation: bounce 1s ease-in-out 0.8s infinite,pulse 2s infinite alternate;
}
#loader span:nth-child(6){
  animation: bounce 1s ease-in-out 1s infinite,pulse 2s infinite alternate;
}

@keyframes pulse {
  0% {background-color: #FFAC5E;}
  20% {background-color: #E83895;}
  40% {background-color: #564AFF;}
  60% {background-color: #38E8D8;}
  80% {background-color: #8CFF3D;}
  100% {background-color: #FFAC5E;}
}

@keyframes bounce{
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

.rightArrow {
  position: absolute;
  right:5px;
  width:50px;
  height:50px;
  cursor: pointer;
  top: 75px;
  line-height: 50px;
}

.leftArrow {
  position: absolute;
  left:5px;
  width:50px;
  height:50px;
  cursor: pointer;
  top: 75px;
  line-height: 50px;
}

    .item {
        max-height:325px;
        height:325px;
        min-height:325px;
        margin-bottom:15px;
        padding:0 8px;
    }
    .itemWrap {
        min-height:325px;
        max-height:325px;
        height:325px;
        margin-bottom:0px;

        .card {
            height:100%;
        }
    }
    .card-body {
        margin:15px !important;
        padding:0px !important;
    }
    .price {
        position:absolute;
        bottom:0;
        right:0;
        height: 75px;
        text-align:center;
        line-height:25px;
        width:150px;
        border-top-left-radius:5px;
        border-top:1px solid #efefef;
        border-left:1px solid #efefef;
        // border-bottom-right-radius: 3px;
        // background-color:#C92227;
        background-color: #f4f4f4;
    }
    .topData {
        position:absolute;
        top:5px;
        left:5px;
        z-index:0;

        span {
            padding:0 3px;
            background-color:#fff;
        }
    }
    .switch {
      display: inline-block;
      height: 17px;
      position: relative;
      width: 30px;
      float:left;
    }

    .switch input {
      display:none;
    }

    .slider {
      background-color: #23201F;
      bottom: 0;
      cursor: pointer;
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: .4s;
    }

    .slider:before {
      background-color: #fff;
      bottom: 2px;
      content: "";
      height: 13px;
      left: 2px;
      position: absolute;
      transition: .4s;
      width: 13px;
    }

    input:checked + .slider {
      background-color: #C92227;
    }

    input:checked + .slider:before {
      transform: translateX(13px);
    }

    .slider.round {
      border-radius: 17px;
    }

    .slider.round:before {
      border-radius: 50%;
    }
    .switchWrap {
        width:100%;
        position:relative;
        float:left;
        margin-top:21px;
        text-align:center;
        
        a {
            height:20px;
            line-height:17px;
            float:left;
            font-size:.9em
        }
    }
    .searchBar {
        position:relative;
        float:left;
        margin:12px 0px;
        height:34px !important;

        input {
            float:left;
            position:relative;
            height:34px;
            width:246px;
            border-color:#D1D1D1;
        }

        div {
            position:relative;
            float:left;
        }
        button {
            position:relative;
            float:left;
            border-top-right-radius:3px;
            border-bottom-right-radius:3px;
            font-size:.9em;
            line-height: 20px;
        }
    }
    .loadButton {
        width:150px;
        border-radius:5px;
        margin-bottom:25px;
        color:white;
        font-weight:bold;
    }
    .bandonHeader {
        background-color:#f2f2f2;
        width:100%;
        // margin-bottom:15px;
        position:relative;
        
        z-index:5;
        box-shadow: 0 0 10px #ccc;
        

        .imagesWrap {
            position:relative;
            float:left;
            width:175px;
        }

        .everythingElse {
            position:relative;
            float:left;
            padding-top:10px;
            padding-left:20px;

            h2 {
                margin-bottom:25px;
            }
        }

        #filterDiv {
            float:left;
            margin-top:10px;
            padding-left: 25px;
            width: calc(100% - 615px);
        }
    }

    #bottomSection {
        width:100%;float:left;margin-top:57.25px;
        background-color: #232323;
    }

    #bottomSmallSection {
        width:100%;
        float:left;
        // margin-top:48px;
        padding-top:15px;
        height: calc(100vh - 48px);
        overflow-y: scroll;
        background-color: #232323;
    }
    .impButton {
        height:34px;
        margin-left:-48px;
        width:48px;
        color:white;
        font-weight:bold;
        background-color:blue;
    }
    .impButtonColors {
        background-color:#C92227;
        border-color:#CED4DA;

        &:hover {
            background-color:#a91d21;
        }
    }

    .btn-black {
        background-color:#000 !important;
        color: #fff !important;
    }
    .imgContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        text-align:center; /* Align center inline elements */
        font: 0/0 a;
    }

    .imgContainer:before {
        content: ' ';
        display: inline-block;
        vertical-align: middle;
        height: 100%;
    }

    .imgContainer > img {
        vertical-align: middle;
        display: inline-block;
        max-height:200px !important;
        max-width:100% !important;
        z-index:9;
    }
    .modal-content {
        margin:auto;
    }
</style>
